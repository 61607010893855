<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$entitiesName.Agency"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" cy-data="cy-agency-title">
          <breadcrumb
            location="/sst/agencies"
            :text="$t('entities.agency')"
          ></breadcrumb>
          <agency-display :value="agency" />
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("entities.users") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                @click="currentTab = 2"
              >
                <a>{{ $t("common.contractorAgreement") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 3 }"
                @click="currentTab = 3"
              >
                <a>{{ $t("agencies.administrativeDocuments") }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 0">
          <many2one-field
            :label="$t('entities.company')"
            v-model="agency.company"
            :fetch="$api.fetchCompanies"
            reference="name"
            @click="(item) => $router.push('/sst/company/' + item.id)"
            :columns="companyMany2OneColumns"
            :inline="true"
            required
            :hasFilter="true"
            v-validate="{ required: true }"
            name="company2one"
            cy-data="company"
            :error="errors.has('company2one')"
          ></many2one-field>

          <text-field
            cy-data="edifice"
            :label="$t('entities.agency')"
            v-model="agency.edifice"
            :readonly="siret && siret.inThirdParty !== false"
            required
          ></text-field>
          <siret-field
            :label="$t('common.siret')"
            v-model="siret"
            cyData="siret"
            :siret="agency.siret"
          ></siret-field>
          <text-field
            cy-data="manager"
            :label="$t('common.manager')"
            v-model="agency.manager"
          ></text-field>
          <text-field
            cy-data="phone"
            :label="$t('common.phone')"
            v-model="agency.phone"
          ></text-field>
          <text-field
            cy-data="mail"
            :label="$t('common.email')"
            type="email"
            v-model="agency.mail"
          ></text-field>
          <textarea-field
            cy-data="address"
            :label="$t('common.address')"
            :readonly="siret && siret.inThirdParty !== false"
            v-model="agency.address"
          ></textarea-field>

          <checkbox-field
            v-if="agency.inThirdParty !== false"
            label="Suivi e-attestation"
            v-model="agency.synchronize"
          ></checkbox-field>

          <text-field
            v-if="editMode && agency.synchronize && siret && !siret.onboarded"
            type="email"
            :label="$t('agencies.columns.inviteMail')"
            v-model="agency.inviteMail"
          ></text-field>
          <article
            v-if="editMode && siret && agency.synchronize && !siret.onboarded"
            slot="helper"
            class="message is-info"
          >
            <div class="message-body">{{ $t("agencies.inviteMailHint") }}</div>
          </article>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 1">
          <div class="is-full-width is-pulled-right">
            <button
              v-if="getRights($entitiesName.userAgency).create"
              class="button is-success is-pulled-right"
              @click.stop="$refs.addUserToAgencyModal.open()"
            >
              {{ $t("agency.addUser") }}&nbsp;
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <flexi-table
            class="is-full-width"
            :loader="fetchUsers(id)"
            :columns="userTableColumns"
            :showIndex="true"
            ref="agencyUsersTable"
          >
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.mail }}</td>
                <td>{{ item.role && item.role.name }}</td>
                <td>
                  <button
                    v-if="getRights($entitiesName.userAgency).delete"
                    class="button is-small is-danger"
                    @click="removeUser(item)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </template>
          </flexi-table>

          <modal
            @create="addUserToAgency"
            @close="closeAddUserModal"
            ref="addUserToAgencyModal"
          >
            <datatable
              :fetch="$api.fetchUsers"
              :singleSelect="true"
              v-model="addUser"
              :size="15"
            >
              <div slot="name" slot-scope="{ item }" :title="$t('common.name')">
                {{ item.name }}
              </div>
              <div slot="mail" slot-scope="{ item }" :title="$t('common.mail')">
                {{ item.mail }}
              </div>
              <div
                slot="role"
                slot-scope="{ item }"
                :title="$t('common.profile')"
              >
                {{ item.role && item.role.name }}
              </div>
            </datatable>
          </modal>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 2">
          <flexi-table
            class="is-full-width"
            :loader="fetchMarket(id)"
            :columns="marketTableColumns"
            :showIndex="true"
            ref="agencyMarketTable"
          >
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.edifice }}</td>
                <td>{{ item.project && item.project.name }}</td>
                <td>
                  <restricted-link
                    :entity="$entitiesName.SubcontractorMarket"
                    :to="'/sst/market/' + item.id"
                    linkClass="button is-small is-primary"
                  >
                    <i class="fa fa-search"></i>
                  </restricted-link>
                </td>
              </tr>
            </template>
          </flexi-table>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab === 3">
          <div v-if="!folder || folder.length === 0">
            <h5 class="title is-5">
              {{ $t("agencies.nolegalDocuments") }}
            </h5>
          </div>

          <div
            v-for="(category, index) in folder"
            :key="'cat_' + index"
            class="mb-6"
          >
            <h4 class="title is-5 mt-2 is-uppercase">
              {{ category.name }}
              <i
                class="fa fa-folder"
                :class="{
                  'has-text-success': category.isComplete,
                  'has-text-danger': !category.isComplete,
                }"
              ></i>
            </h4>

            <div
              v-for="(document, index) in category.documents"
              :key="'doc_' + index"
              class="mb-2"
            >
              <span
                class="tag is-medium is-rounded mr-2 has-background-white"
                :class="{
                  'has-text-success has-border-success': document.available,
                  'has-text-danger has-border-danger': !document.available,
                }"
              >
                <i
                  class="fa mr-2 is-size-4"
                  :class="{
                    'fa-check-circle': document.available,
                    'fa-question-circle': !document.available,
                  }"
                  style="margin-left: -6px"
                ></i>
                <span>
                  <b>{{ document.available ? "Présent" : "Absent" }}</b>
                </span>
              </span>
              <span
                ><b>{{ document.name }}</b></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "agency",
  props: ["id"],
  data() {
    return {
      agency: {
        company: {},
      },
      siret: null,
      addUser: null,
      companyMany2OneColumns: {
        name: this.$t("common.name"),
        edifice: this.$t("common.edifice"),
        siren: this.$t("common.siren"),
        manager: this.$t("common.manager"),
        mail: this.$t("common.mail"),
      },
      userTableColumns: [
        {
          title: this.$t("common.name"),
          name: "name",
          accessor: "name",
        },
        {
          title: this.$t("common.email"),
          name: "mail",
          accessor: "mail",
        },
        {
          title: this.$t("common.profile"),
          name: "role",
          accessor: "role.name",
        },
      ],
      marketTableColumns: [
        {
          title: this.$t("common.edifice"),
          name: "edifice",
          accessor: "edifice",
        },
        {
          title: this.$t("common.project"),
          name: "project",
          accessor: "project.name",
        },
        {
          title: "",
          name: "id",
          accessor: "id",
        },
      ],
      currentTab: 0,
      folder: [],
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
    }),
  },
  watch: {
    siret(val) {
      if (!val) {
        return;
      }

      this.agency.inThirdParty = val.inThirdParty;
      this.agency.siret = val.siret;

      if (!val.inThirdParty) {
        return;
      }

      this.agency.edifice = val.name;
      this.agency.address = val.address;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/agency/${this.id}`).then((response) => {
        this.agency = response.data;
      });

      this.folder = [];
      axios.get(`/agency/${this.id}/legal`).then((response) => {
        this.folder = response.data;
      });
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return Promise.resolve();
        }

        return axios
          .put(`/agency/${this.id}`, this.agency)
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch("states/setEdition", false);
            }
          })
          .finally(() => {
            this.fetchData();
          });
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      axios.get(`/agency/${this.id}`).then((response) => {
        this.agency = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    remove() {
      return this.$awn.confirm(this.$t("expressions.delete"), () =>
        axios.delete(`/agency/${this.id}/delete`).then((response) => {
          if (response.data.success) {
            this.$router.replace("/sst/agencies");
          }
        })
      );
    },
    fetchUsers(id) {
      return this.$api.fetchUsersByAgency.bind(this, id);
    },
    fetchMarket(id) {
      return this.$api.fetchMarketsByAgency.bind(this, id);
    },
    addUserToAgency() {
      axios.post(`/user/${this.addUser.id}/agency/${this.id}`).then(() => {
        this.$refs.agencyUsersTable.fetch();
        this.$refs.addUserToAgencyModal.close();
      });
    },
    removeUser(user) {
      this.$awn.confirm(this.$t("expressions.delete"), () => {
        axios.delete(`/user/${user.id}/agency/${this.id}`).then(() => {
          this.$refs.agencyUsersTable.fetch();
        });
      });
    },
    closeAddUserModal() {
      this.addUser = null;
    },
  },
};
</script>

<style lang='scss' scoped>
.infos {
  .no-border {
    border: none;
    > span {
      width: 30%;
      display: inline-block;
    }
  }
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.is-full-width {
  flex: 1;
  width: 100%;
}

.has-border-success {
  border: 1px solid #00a65a;
}

.has-border-danger {
  border: 1px solid #ef413d;
}
</style>
